import React, { useState, useEffect, Fragment } from 'react';
import { Button, Card, CardContent, Divider, Grid, IconButton, Typography } from "@mui/material";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import LabelsList from '../../../../components/CommonFunctions/LabelsList';
import { Link } from 'react-router-dom';
import QuickEdit from '../../../../components/Popup/QuickEditTab';
import DataTable from './Notes';

const Details = ({ viewID, setFormValue, control, watch, assignees, isDisable, setValue, statusList, func, data }) => {
    const [Loading, setLoading] = useState(false)
    const [isMounted, setIsMounted] = useState(false);
    const [open, setOpen] = useState(false)


    const { labels, isLoading, fetchLabels, } = LabelsList();


    useEffect(() => {
        setIsMounted(true)
        if (isMounted) {

            fetchLabels()
        }

        return () => {
            setIsMounted(false)
        }
    }, [isMounted])


    const handleQuickEditModal = () => {
        setOpen(true)
    }
    const parsedData = data?.extra_data ? JSON.parse(data.extra_data) : {};
    const keyValuePairs = Object.entries(parsedData).map(([key, value]) => ({ key, value }));
    console.log("key value", keyValuePairs);


    return (

        <div>
            {/* <QuickEdit open={open} setOpen={setOpen} id={data?.id} /> */}
            <div class="pop-det-cntr source sourse-list">
                {/* <Grid display={'flex'} justifyContent={'end'} >
                    <IconButton sx={{
                        border: '1px solid #5046e4',
                        padding: '4px'
                    }} onClick={handleQuickEditModal}><ModeEditOutlineIcon sx={{ color: '#5046e4' }} /></IconButton>
                </Grid> */}
                <div div class="pop-det-cntr" >

                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                            Lead Type:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.crm_lead_type?.name}
                        </Typography>

                    </div>
                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                            Project:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.project?.name}
                        </Typography>

                    </div>
                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                            Customer Name:

                        </Typography>

                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.name}
                        </Typography>
                    </div>

                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                            Phone Number:

                        </Typography>

                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.phone_number}
                        </Typography>
                    </div>
                    {keyValuePairs
                        .filter(({ key }) => key === "whatsapp_number")
                        .map(({ key, value }, index) => (
                            <Fragment key={index}>
                                <div className="pop-det-text">
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        {key}:
                                    </Typography>
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {value}
                                    </Typography>
                                </div>
                            </Fragment>
                        ))}

                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                            Email:

                        </Typography>

                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.email}
                        </Typography>
                    </div>


                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Age:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                        </Typography>

                    </div>


                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Profession:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                        </Typography>

                    </div>
                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Location:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.location}
                        </Typography>

                    </div>

                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Source:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.source}
                        </Typography>

                    </div>


                    <div class="pop-det-text"  >
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Source URL:</Typography>
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {data?.source_url}
                        </Typography>

                    </div>


                </div>

            </div>
            <DataTable viewID={viewID} />


        </div>

    );
};

export default Details;
