import React, { useState, useEffect } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { Leads } from '../../api/Endpoints/Leads';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Badge from '@mui/material/Badge';
import useAssignees from '../CommonFunctions/Usres';
import useProjects from '../CommonFunctions/ProjectsDropdown';
import LeadConvertPopup from '../Popup/LeadConvertPopup';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AllProjects from '../CommonFunctions/AllprojectDropdown';
const AssignProject = ({ id, DtView, isDisable, func, frmDetailsPage }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { projects, isLoading, fetchAllProjects } = AllProjects();
    const [showconvertPopup, setShowconvertPopup] = useState(false)
    const [triagID, setTriagID] = useState()
    const [project, setProject] = useState()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        fetchAllProjects()
    };


    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleAssigneeClick = (project) => {
        setProject(project)
        setTriagID(id)
        setShowconvertPopup(true)
        handleClose();
    };



    const open = Boolean(anchorEl);

    return (
        <>
            {showconvertPopup && (
                <LeadConvertPopup setShowPopup={setShowconvertPopup} ProjectID={project.id} setProjectID={setProject} triagID={triagID} setTriageID={setTriagID} func={func} frmDetailsPage={frmDetailsPage} />
            )}
            <Button class="assets-btn" onClick={handleClick} disabled={isDisable} style={{ cursor: 'pointer' }}>

                {project ? (
                    DtView ? (
                        <Button sx={{ color: 'black', }}>{project.name}</Button>
                    ) : (
                        <Badge badgeContent={project?.name.substr(0, 2)} color="primary" />
                    )
                ) : (
                    <AssignmentTurnedInOutlinedIcon />
                )}

            </Button >
            <Popover
                class="assign-cntr"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        minHeight: '10px',
                        maxHeight: '30vh',
                        overflowY: 'auto',
                    },
                }}
            >
                {
                    isLoading ? (
                        <Typography variant="body1" sx={{ p: 2 }}>
                            Loading...
                        </Typography>
                    ) : (
                        <Typography className="assign-cntr" variant="body1" sx={{ p: 2 }}>
                            {projects.length > 0 ? (
                                projects.map((project) => (
                                    <span key={project.id} style={{ fontWeight: 'bold' }}>
                                        <Button
                                            sx={{ width: '100%' }}
                                            onClick={() => handleAssigneeClick(project)}
                                            variant="contained"
                                        >
                                            {project.name}
                                        </Button>
                                    </span>
                                ))
                            ) : (
                                <Typography variant="body2" sx={{ color: 'gray', textAlign: 'center' }} >
                                    No projects found
                                </Typography>
                            )}
                        </Typography>

                    )
                }
            </Popover >
        </>
    );
};

export default AssignProject;
