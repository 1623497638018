import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import DateIn from '../Form/Date';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAssignees, fetchCampaginName, fetchLeadSources, fetchUsersUnderMe } from '../CommonFunctions/DropdownLists';
import SelectX from '../Form/SelectX';
import ReactSelector from 'react-select';
import { LeadSources } from '../../api/Endpoints/LeadSources';
import TextInput from '../Form/TextInput';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal({ open, setOpen, watch, control, reset, handleFilter, setFilterData, setValue, frmReportMenu }) {
    const [leadSource, setLeadSource] = React.useState([])
    const handleClose = () => {
        setFilterData(null)
        setOpen(false)
        reset()
    }
    const handleCleare = () => {
        setFilterData(null)
        reset()
        setValue('assing_to_users', null)
        setValue('lead_source', null)
    }






    return (
        <div>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                PaperProps={{
                    style: {
                        minHeight: '350px',
                        minWidth: '100px',
                    },
                }}
            >
                <Grid display={'flex'} justifyContent={'space-between'} paddingTop={1}>
                    <DialogTitle style={{ fontSize: 20, paddingTop: 13, paddingBottom: 10 }}>Filters</DialogTitle>
                    <IconButton onClick={handleClose} sx={{ marginRight: 3 }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>

                <DialogContent>

                    <Grid container spacing={2} sx={{ display: 'flex', mt: 0 }}>


                        <Grid item xs={12} sm={6}>
                            <DateIn
                                control={control}
                                name="from"
                                label="From"
                                value={watch('from')}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateIn
                                control={control}
                                name="to"
                                label="To"
                                value={watch('to')}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {!frmReportMenu ? (
                                <SelectX
                                    ismultiple
                                    options={fetchAssignees}
                                    label="Assigned User"
                                    name={'assing_to_users'}
                                    defaultValue={watch('assing_to_users')}
                                    control={control}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        // singleValue: (base, state) => {
                                        //     return {
                                        //         ...base,
                                        //         backgroundColor: state.data.bg_color,
                                        //         fill: state.data.text_color
                                        //     };
                                        // },

                                        multiValue: (base, state) => {
                                            return {
                                                ...base,
                                                backgroundColor: state.data.bg_color,
                                                fill: state.data.text_color
                                            };
                                        },

                                        multiValueLabel: (base, state) => ({
                                            ...base,
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                            color: state.data.text_color
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            color: 'black',
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                        }),

                                    }}
                                />
                            ) : (
                                <SelectX

                                    options={fetchUsersUnderMe}
                                    label="Users Under Me"
                                    name={'user_id'}
                                    defaultValue={watch('user_id')}
                                    control={control}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),


                                        multiValue: (base, state) => {
                                            return {
                                                ...base,
                                                backgroundColor: state.data.bg_color,
                                                fill: state.data.text_color
                                            };
                                        },

                                        multiValueLabel: (base, state) => ({
                                            ...base,
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                            color: state.data.text_color
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            color: 'black',
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                        }),

                                    }}
                                />
                            )}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {!frmReportMenu ? (
                                <SelectX
                                    ismultiple
                                    options={fetchLeadSources}
                                    label="Lead Source"
                                    name={'lead_source'}
                                    defaultValue={watch('lead_source')}
                                    control={control}
                                    isSearchable={false}

                                />
                            ) : (
                                <SelectX
                                    options={fetchLeadSources}
                                    label="Lead Source"
                                    name={'lead_source'}
                                    ismultiple
                                    defaultValue={watch('lead_source')}
                                    control={control}
                                    isSearchable={false} hideSelectedOptions={false}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        multiValue: (base, state) => {
                                            return {
                                                ...base,
                                                backgroundColor: state.data.bg_color,
                                                fill: state.data.text_color
                                            };
                                        },

                                        multiValueLabel: (base, state) => ({
                                            ...base,
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                            color: state.data.text_color
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            color: 'black',
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                        }),

                                    }}
                                />
                            )}
                        </Grid>
                        {frmReportMenu && (
                            <Grid item xs={12} sm={6}>
                                <SelectX
                                    options={fetchCampaginName}
                                    label="Campaign Name"
                                    name={'campaign_name'}

                                    defaultValue={watch('campaign_name')}
                                    control={control}
                                    isSearchable={false}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        // singleValue: (base, state) => {
                                        //     return {
                                        //         ...base,
                                        //         backgroundColor: state.data.bg_color,
                                        //         fill: state.data.text_color
                                        //     };
                                        // },

                                        multiValue: (base, state) => {
                                            return {
                                                ...base,
                                                backgroundColor: state.data.bg_color,
                                                fill: state.data.text_color
                                            };
                                        },

                                        multiValueLabel: (base, state) => ({
                                            ...base,
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                            color: state.data.text_color
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            color: 'black',
                                            backgroundColor: 'hsl(0, 0%, 90%)',
                                        }),

                                    }}
                                />
                            </Grid>
                        )}
                        {/* <Grid item xs={12} sm={8}>
                            <TextInput control={control}
                                name="searchbynameorphoneoremail"
                                label="Search by (Name, Email, or Phone)"
                                value={watch('searchbynameorphoneoremail')} />
                        </Grid> */}
                    </Grid>

                </DialogContent >

                <DialogActions>
                    <Button
                        color='error'
                        variant='outlined'
                        onClick={handleCleare}>


                        Clear
                    </Button>

                    <Button
                        variant='outlined'
                        onClick={handleFilter}
                    >

                        Apply
                    </Button>
                </DialogActions>

            </Dialog >

        </div >
    );
}