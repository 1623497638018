import { Leads } from '../../../api/Endpoints/Leads'
import { Fragment, useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CustomToolbar from '../../../components/Tools/CustomeToolbar';
import { useForm } from 'react-hook-form';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Restore from '../../../components/Popup/Restore';
import RestoreIcon from '@material-ui/icons/Restore';
import StatusSelector from '../../../components/Form/Status';
import { RecycleAPI } from '../../../api/Endpoints/RecycleBin';
import { Waveform } from '@uiball/loaders';
import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';
import StatusLIstFunction from '../../../components/CommonFunctions/statusLIst';
import { TriageLeadsAPI } from '../../../api/Endpoints/TriageLeadsApi';
import AssignProject from '../../../components/Form/AssignProject';
import TriageDetails from './Details';
import { Link } from 'react-router-dom';
import { fetchProjects } from '../../../components/CommonFunctions/DropdownLists';


export default function TriageLeads() {


    const columns = [

        {
            field: 'name',
            headerName: 'Customer Name',
            width: 300,
            renderCell: (params) => (

                <span
                >
                    <Link to={`/dashboard/triage/triage-details/${params.id}`} style={{ textDecoration: 'none', color: 'black', whiteSpace: 'break-spaces' }}>
                        {params.row.name}  </Link>
                </span>
            ),
        },



        {
            field: 'created_at',
            headerName: 'Date',
            width: 300,
            renderCell: (params) => {
                const formattedDate = moment.utc(params.row.created_at).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },


        {
            field: 'assigned_to_details',
            headerName: 'Assign Project',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        <AssignProject
                            id={params.id}
                            name={`assigned_to_details`}
                            setValue={setValue}
                            watch={watch}
                            func={fetchTriagLeads}
                        />

                    </Button>
                </>
            ),



        },
        {
            field: 'source_url',
            headerName: 'Source URL',
            width: 400,
            renderCell: (params) => (

                <span style={{ textDecoration: 'none', color: 'black', whiteSpace: 'break-spaces', cursor: 'pointer' }}>


                    {params.row.source_url}
                </span>

            ),
        },
    ]


    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})



    const [error, setErr] = useState()
    const [triage, setTriageLeads] = useState([])
    const [loading, setLoading] = useState(false);
    const { GlobalRefresh } = GlobalRefreshContext();
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const fetchTriagLeads = () => {
        setLoading(true);
        TriageLeadsAPI.get({
            page: parseInt(paginationModel.page) + 1,
            crm_lead_type_id: leadTypeData?.ID,
            project_id: projectData.ID
        }).then(response => {
            if (response.data.status == false) {
                setErr(response.data?.message)
            } else {
                setTriageLeads(response.data?.triages)
            }
            setLoading(false);
        }).catch(error => {
            console.log(error.message);
            setLoading(false);
        })
    }




    useEffect(() => {
        fetchTriagLeads();
    }, [paginationModel, GlobalRefresh]);




    return (

        <div class="page-cntr">


            {/* <TriageDetails /> */}
            <div class="lead-table recyclebin-table recy-page-cntr">

                {/* Popup's  */}




                <div class="title-sec">
                    <PageHeader title={'Triage Leads'} total={!loading && triage?.total} />
                    {
                        triage?.data ?
                            <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >

                            </Card>
                            : null
                    }
                </div>


                {
                    loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <>
                                {triage?.data?.length > 0 ? (
                                    <Fragment>
                                        < Card sx={{
                                            m: 0,
                                        }} variant="outlined" class="lead-table" >


                                            {
                                                typeof triage === "object" &&
                                                <DataGrid
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    paginationMode="server"
                                                    rows={triage?.data}
                                                    paginationModel={paginationModel}
                                                    columns={columns}
                                                    rowCount={triage?.total}
                                                    rowHeight={70}
                                                    autoHeight={true}
                                                    scrollbarSize={10}
                                                    density="comfortable"
                                                    onPaginationModelChange={setPaginationModel}
                                                    disableColumnMenu
                                                    disableRowSelectionOnClick
                                                    disableExtendRowFullWidth
                                                />
                                            }

                                        </ Card>
                                    </Fragment>
                                ) : (

                                    <Box sx={{ width: '100%', marginTop: 15, height: 20, display: 'flex', justifyContent: 'center' }}>
                                        <Typography fontWeight={500}> {error || "Triage Leads not found."}</Typography>
                                    </Box>
                                )}

                            </>
                        )}
            </div >
        </div >

    )
};
