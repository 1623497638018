
import { Projects } from '../../api/Endpoints/project';
import { Leads } from '../../api/Endpoints/Leads';
import { DynamicdropdownsAPI } from '../../api/Endpoints/DynamicDropdown';
import { LeadSources } from '../../api/Endpoints/LeadSources';
import { UsersUnderMe } from '../../api/Endpoints/UserUnderMe';
import { CampaignsAPI } from '../../api/Endpoints/CampaignsAPI';


export const fetchAssignees = async (e) => {
    const response = await Leads.getAssignees({ keyword: e });
    if (typeof response.data.status) {
        return response.data?.users;
    } else {
        return [];
    }
}

export const fetchLeads = async (e) => {
    const response = await Leads.getDropdownList({ keyword: e });
    if (typeof response.data.status) {
        return response.data?.crm_lead_types
    } else {
        return [];
    }

}

export const fetchProjects = async (e) => {
    const response = await Projects.getProjectList({ keyword: e });
    if (typeof response.data.projects) {
        return response.data?.projects;
    } else {
        return [];
    }
}



export const fetchStatus = async (e) => {
    const response = await DynamicdropdownsAPI.get({ keyword: e });
    if (typeof response.data.status) {
        return response.data?.crm_status;
    } else {
        return [];
    }
}

export const fetchLeadDropdownStatus = async (e) => {
    const response = await DynamicdropdownsAPI.get({ keyword: e });
    if (response.data.status) {
        return response.data.crm_status
    }
}


export const fetchLeadSources = async (e) => {
    const response = await LeadSources.get({ keyword: e });
    if (response.data.status) {
        const nonNullSources = response.data.data.filter(item => item.source !== null);
        const formattedSources = nonNullSources.map((res, index) => ({
            id: index + 1,
            name: res.source
        }));
        return formattedSources;
    }

    return [];
};
export const fetchUsersUnderMe = async (e) => {

    const response = await UsersUnderMe.get({ keyword: e });

    if (response.data.status) {
        const formattedSources = response.data.data.
            users
            .map((res) => ({
                id: res.id,
                name: res.name
            }));
        return formattedSources;
    }

    return [];
};
export const fetchCampaignTypes = async (e) => {
    const response = await CampaignsAPI.getCampaignTypes({ keyword: e });

    if (response.data.campaign_types
    ) {
        const nonNullSources = response.data.campaign_types.filter(item => item.type !== null);
        const formattedSources = nonNullSources.map((res) => ({
            id: res.id,
            name: res.type
        }));
        return formattedSources;
    }

    return [];
};

export const fetchCountries = async (e) => {
    const response = await DynamicdropdownsAPI.getCountryList({ search: e });
    if (response.data) {
        const nonNullSources = response.data
            .filter(item => item.id !== null);
        const formattedSources = nonNullSources.map((res) => ({
            id: res.id,
            name: res.name
        }));
        return formattedSources;
    }
    return [];
};

export const fetchAgeList = async (e) => {
    const response = await DynamicdropdownsAPI.getAgeList({ search: e });
    if (response.data) {
        const nonNullSources = response.data
            .filter(item => item.id !== null);
        const formattedSources = nonNullSources.map((res) => ({
            id: res.id,
            name: res.name
        }));
        return formattedSources;
    }
    return [];
};

export const fetchProfessionals = async (e) => {
    const response = await DynamicdropdownsAPI.getProfessinalList({ search: e });
    if (response.data) {
        const nonNullSources = response.data
            .filter(item => item.id !== null);
        const formattedSources = nonNullSources.map((res) => ({
            id: res.id,
            name: res.name
        }));
        return formattedSources;
    }
    return [];
};

export const fetchCampaginName = async (e) => {
    const response = await CampaignsAPI.getCampginNames({ search: e });
    if (response.data.data) {
        const nonNullSources = response.data.data
            .filter(item => item.id !== null);
        const formattedSources = nonNullSources.map((res) => ({
            id: res.id,
            name: res.name
        }));
        return formattedSources;
    }
    return [];
};


