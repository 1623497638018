import { useState } from "react";
import { Projects } from "../../api/Endpoints/project";

const AllProjects = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAllProjects = async () => {
        setIsLoading(true);
        try {
            const response = await Projects.allProject();
            setProjects(response.data.projects);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    return { projects, isLoading, fetchAllProjects };
};

export default AllProjects;
