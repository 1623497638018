import React from 'react';
import { FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";

const SelectX = (props) => {
    const { type, options, label, control, name, ismultiple, defaultValue, error, error2, isSearchable, closeMenuOnSelect } = props;
    const onInputChange = (e) => {
        options(e);
    };


    const isReadOnly = type === "read-only";

    return (
        <FormControl sx={{ width: '100%' }}>
            <label className={"css-1wjmx8d-MuiFormLabel-root-MuiInputLabel-root"}>{label}</label>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}

                render={({ field: { value, onChange } }) => (
                    <AsyncSelect
                        value={value}
                        onChange={onChange}
                        isClearable={true}
                        isMulti={ismultiple}
                        hideSelectedOptions={false}
                        defaultOptions
                        isSearchable={isSearchable}
                        cacheOptions={false}
                        loadOptions={options}
                        getOptionLabel={e => e.assigned_to_user || e.name || e.title || e.source || e.type}
                        getOptionValue={e => e.id}
                        menuPortalTarget={document.body}
                        isDisabled={isReadOnly}
                        placeholder={props.placeholder}
                        closeMenuOnSelect={closeMenuOnSelect || false}
                        autoFocus={props.autoFocus}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            // singleValue: (base, state) => {
                            //     return {
                            //         ...base,
                            //         backgroundColor: state.data.bg_color,
                            //         fill: state.data.text_color
                            //     };
                            // },

                            multiValue: (base, state) => {
                                return {
                                    ...base,
                                    backgroundColor: state.data.bg_color,
                                    fill: state.data.text_color
                                };
                            },

                            multiValueLabel: (base, state) => ({
                                ...base,
                                backgroundColor: 'hsl(0, 0%, 90%)',
                                color: 'black'
                            }),
                            multiValueRemove: (base) => ({
                                ...base,
                                color: 'black',
                                backgroundColor: 'hsl(0, 0%, 90%)',
                            }),

                        }}
                    />

                )}
            />
            {/* {error && <Typography variant={"string"} sx={{ color: "#ec4c47", fontSize: '0.75rem' }}>{error}</Typography>} */}
            {error2 && <Typography variant={"string"} sx={{ color: "#ec4c47", fontSize: '0.75rem' }}>{error2}</Typography>}
        </FormControl>
    );
};

export default SelectX;
