import React, { useEffect } from 'react';
import SelectX from '../components/Form/SelectX';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Leads } from '../api/Endpoints/Leads';
import { Projects } from '../api/Endpoints/project';
import { GlobalRefreshContext } from '../contexts/GlobalRefresh/GlobalRefresh';

function NavDropdown() {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm();
    const { setGlobalRefresh } = GlobalRefreshContext();

    const fetchLeads = async (e) => {
        const response = await Leads.getDropdownList({ keyword: e });
        if (typeof response.data.status) {
            return response.data?.crm_lead_types;
        } else {
            return [];
        }
    };

    const fetchProjects = async (e) => {
        const response = await Projects.getProjectList({ keyword: e });
        if (typeof response.data.projects) {
            return response.data?.projects;
        } else {
            return [];
        }
    };

    useEffect(() => {
        onChange();
    }, [watch('lead_type'), watch('project')]);

    const onChange = () => {
        const lead_type = watch('lead_type');
        const project = watch('project');

        // if (lead_type && project_type) {
        //     localStorage.setItem('Lead_type_ID', lead_type?.id);
        //     localStorage.setItem('Lead_type_Name', lead_type?.name);
        //     localStorage.setItem('Project_type_ID', project_type?.id);
        //     localStorage.setItem('Project_type_Name', project_type?.name);
        // }
        if (lead_type !== undefined) {
            const leadTypeData = {
                ID: lead_type?.id || null,
                Name: lead_type?.name || null
            };
            setGlobalRefresh(Math.random)
            localStorage.setItem('Lead_type', JSON.stringify(leadTypeData));
        }
        if (project !== undefined) {
            const project_data = {
                ID: project?.id || null,
                Name: project?.name || null
            };
            setGlobalRefresh(Math.random)
            localStorage.setItem('project', JSON.stringify(project_data));
        }
    };

    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));


    return (
        <Grid container spacing={2} justifyContent={'start'}>
            <Grid item sm={3}>
                <SelectX
                    options={fetchLeads}
                    name={'lead_type'}
                    defaultValue={
                        leadTypeData?.ID
                            ? {
                                id: leadTypeData?.ID,
                                name: leadTypeData?.Name
                            }
                            : null
                    }
                    placeholder={"Lead Type"}
                    control={control}
                />

            </Grid>
            <Grid item sm={4}>
                <SelectX
                    options={fetchProjects}
                    name={'project'}
                    closeMenuOnSelect={true}
                    defaultValue={
                        projectData?.ID
                            ? {
                                id: projectData?.ID,
                                name: projectData?.Name
                            }
                            : null
                    }
                    placeholder={"Project"}
                    control={control}
                />


            </Grid>
        </Grid>
    );
}

export default NavDropdown;
